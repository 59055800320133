import React, { Dispatch, SetStateAction } from "react";
import { i18n } from "../../translate/i18n";
import { Grid } from "@mui/material";
import { OrderModel } from "../../domain/models/commerce/OrderModel";
import {
	formatDateStringOnly,
	formatPhoneNumber,
} from "../../infrastructure/utils/FormatMask";
import { formatCurrency } from "../../infrastructure/utils/FormatUtils";
import DealerAutocomplete from "../DealerAutocomplete/DealerAutocomplete";
import { DealerDto } from "../../domain/dtos/dealers/DealerDto";
import {
	EROrderAsSimplifiedOrderModel,
	EROrderCheckOptions,
} from "../../screens/ExchangeAndReturn/ERDetails/components/ERItems";

type Props = {
	orderModel: OrderModel | EROrderAsSimplifiedOrderModel;
	currentDealer: DealerDto | null;
	setCurrentDealer: Dispatch<SetStateAction<DealerDto | null>>;
	handleAutocompleteChange: (dealer: DealerDto | null) => void;
	emptyDealersCallback: () => void;
	triggerLoadingDealers: (isLoading: boolean) => void;
	exchangeIntegrationOptions?: EROrderCheckOptions;
};

export default function OrderCheckHeader({
	orderModel,
	currentDealer,
	setCurrentDealer,
	handleAutocompleteChange,
	emptyDealersCallback,
	triggerLoadingDealers,
	exchangeIntegrationOptions,
}: Props) {
	const renderModalityType = (): string => {
		if (exchangeIntegrationOptions) {
			return exchangeIntegrationOptions.reverse_type;
		}
		return i18n.t(
			`orderCheck.${orderModel.isDelivery ? "Delivery" : "PickupInStore"}`
		);
	};

	const headerInfo = () => {
		return (
			<Grid container fontSize={16}>
				<Grid item xs={4}>
					<strong>{i18n.t("orderCheck.OrderModality").toString()} </strong>
					{renderModalityType()}
				</Grid>
				<Grid item xs={4}>
					<strong>{i18n.t("orderCheck.Quantity").toString()} </strong>
					{orderModel.itemQuantity}
				</Grid>
				<Grid item xs={4}>
					<strong>{i18n.t("orderCheck.Client").toString()} </strong>
					{orderModel.buyer?.fullName}
				</Grid>
				<Grid item xs={4}>
					<strong>{i18n.t("orderCheck.EstimateDelivery").toString()} </strong>
					{orderModel.estimateDate
						? formatDateStringOnly(orderModel.estimateDate)
						: "-"}
				</Grid>
				<Grid item xs={4}>
					<strong>{i18n.t("orderCheck.Value").toString()} </strong>
					{formatCurrency(orderModel.totalAmount, true)}
				</Grid>
				<Grid item xs={4}>
					<strong>{i18n.t("orderCheck.Phone").toString()} </strong>
					{orderModel.buyer.phoneNumber
						? formatPhoneNumber(orderModel.buyer.phoneNumber)
						: "-"}
				</Grid>
			</Grid>
		);
	};

	return (
		<Grid container>
			<Grid item xs={12} marginBottom={2}>
				{i18n.t("orderCheck.Order").toString()}{" "}
				<strong>{orderModel.orderCode}</strong>
			</Grid>
			<Grid item xs={9}>
				{headerInfo()}
			</Grid>
			<Grid item xs={3}>
				<DealerAutocomplete
					id="picker"
					currentDealer={currentDealer}
					setCurrentDealer={setCurrentDealer}
					handleAutocompleteChange={handleAutocompleteChange}
					dealerId={null}
					emptyDealersCallback={emptyDealersCallback}
					triggerLoadingDealers={triggerLoadingDealers}
				/>
			</Grid>
		</Grid>
	);
}
