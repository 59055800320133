import React, { Component } from "react";
import Loading from "../../../../../components/Loading";
import InputMask from "react-input-mask";
import { toast } from "react-toastify";
//import {API} from '../../../API'
export class Cpf extends Component {
	constructor(props) {
		super(props);
		this.change = this.change.bind(this);
		this.handleBreakLine = this.handleBreakLine.bind(this);
		this.addCpf = this.addCpf.bind(this);
		this.removeList = this.removeList.bind(this);
		this.remove = this.remove.bind(this);
		this.save = this.save.bind(this);
	}
	state = {
		loading: false,
		cpfs: [],
		delete: false,
		lote: false,
		search: "",
		cpf: [],
		allCheck: false,
		selects: [],
		filterOne: null,
		filterTwo: null,
	};

	convertArrayToObject = (array, key) => {
		const initialValue = {};
		return array.reduce((obj, item) => {
			return {
				...obj,
				[item[key]]: item,
			};
		}, initialValue);
	};

	async addCpf(e) {
		await this.setState({ cpf: e.target.value.split(",") });
	}

	async cpfSubmit() {
		var cpf = this.state.cpf;
		var json = [];

		for (let i = 0; i < cpf.length; i++) {
			json.push({ document: cpf[i] });

			var result = this.state.cpfs.findIndex(
				(element) => element.document === json[i].document
			);
			if (result === -1) {
				await this.setState({ cpfs: this.state.cpfs.concat(json[i]) });
			} else {
				toast.error("Sua lista já possui este cpf! 😃");
			}
		}

		this.setState({ cpf: [] });
		this.setState({ lote: false });
	}

	handleBreakLine(e) {
		if (e.charCode === 13) e.target.value = e.target.value + "\r\n";
	}

	async change(e) {
		let cpfLote = e.target.value;

		await this.setState({
			// PEGA OS CPFS VALIDOS SEM PONTOS
			filterOne: cpfLote
				.replace(
					/(\d{3})?(\d{3})?(\d{3})?(\d{2})/gm,
					function (matchDaRegex, grupo1, grupo2, grupo3, grupo4) {
						return `${grupo1}.${grupo2}.${grupo3}-${grupo4}`;
					}
				)
				.replace(/(?:\r\n|\r|\n|\s)/gm, ",")
				.split(",")
				.filter((e) => {
					return e.replace(/\D/gm, "");
					//return e.replace(/[^\d]+/gm,'')
				}),
		});

		await this.setState({
			// PEGA OS CPFS VALIDOS COM PONTOS
			filterTwo: cpfLote
				.replace(/(?:\r\n|\r|\n|\s)/gm, ",")
				.split(",")
				.filter((e) => {
					return e.replace(/\D/gm, "");
					//return e.replace(/[^0-9]+/gm,'');
					//return e.replace(/[^\d]+/gm,'')
				}),
		});

		let cpfsLote1 = this.state.filterOne;
		let cpfsLote2 = this.state.filterTwo;

		cpfsLote1 = cpfsLote1.filter((item) => item.length === 14);
		cpfsLote2 = cpfsLote2.filter((item) => item.length === 14);

		cpfLote = cpfsLote1.concat(cpfsLote2);

		this.setState({ cpf: cpfLote });
	}

	save(i) {
		if (this.state.cpfs.length > 1) {
			var text = " Cpfs";
		} else {
			var text = " Cpf";
		}
		var item = {
			documents: this.state.cpfs,
			value: this.state.cpfs,
		};
		var itemView = {
			name: "Cpfs",
			value: this.state.cpfs.length + text,
			itemId: "documents",
			modal: "#conditionModalDocuments",
		};
		this.props.newCondition(item, itemView);
		this.setState({ lote: false });
	}

	async remove() {
		this.setState({ delete: true });
	}

	removeList(i) {
		this.state.cpfs.splice(i, 1);
		this.forceUpdate();
	}

	async selectAllItens(e) {
		await this.setState({ allCheck: !this.state.allCheck });

		if (this.state.allCheck) {
			for (let i = 0; i < this.state.cpfs.length; i++) {
				if (
					!this.state.selects.some(
						(cpf) => cpf.id === this.state.cpfs[i].document
					)
				) {
					var item = {
						id: this.state.cpfs[i].document,
						index: i,
					};
					this.state.selects.push(item);
				}
			}
		} else {
			this.state.selects = [];
			this.forceUpdate();
		}

		this.forceUpdate();
	}

	async selectItens(id, index) {
		var item = {
			id: id,
			index: index,
		};

		const found = this.state.selects.some((el) => el.id === id);

		if (!found) {
			this.state.selects.push(item);
		} else {
			const indexRemove = this.state.selects.findIndex((el) => el.id === id);
			this.state.selects.splice(indexRemove, 1);
		}

		if (this.state.selects.length === this.state.cpfs.length) {
			await this.setState({ allCheck: true });
		} else {
			await this.setState({ allCheck: false });
		}

		this.forceUpdate();
	}

	async multDelete() {
		if (this.state.allCheck) {
			this.setState({ allCheck: false, cpfs: [] });
		} else {
			for (let i = 0; i < this.state.selects.length; i++) {
				const indexRemove = this.state.cpfs.findIndex(
					(el) => el.document === this.state.selects[i].id
				);
				this.state.cpfs.splice(indexRemove, 1);
			}
		}
		this.forceUpdate();
		this.state.selects = [];
		this.setState({ delete: false });
	}

	updateSearch(e) {
		this.setState({ search: e.target.value.substr(0, 20) });
	}

	render() {
		let filteredCpfs = this.state.cpfs.filter((cpf) => {
			return cpf.document.indexOf(this.state.search) !== -1;
		});
		return (
			<div
				class="modal fade"
				id="conditionModalDocuments"
				tabIndex="-1"
				role="dialog"
				aria-labelledby="conditionModalDocuments"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-lg" role="document">
					<div className="modal-content">
						{this.state.delete ? (
							<>
								<div className="modal-header">
									<div>
										<h3 className="modal-title">Consumidores por CPF</h3>
									</div>
								</div>
								<div className="modal-body modal-xl pt-0">
									<p>
										Você está excluindo os itens permanentemente, tem certeza
										disso?
									</p>
								</div>
								<div className="modal-footer">
									<button
										type="button"
										className="btn btn-light outline"
										onClick={() => this.setState({ delete: false })}
									>
										Fechar
									</button>
									<button
										type="button"
										className="btn btn-danger glow"
										onClick={() => this.multDelete()}
										data-dismiss={this.state.delete ? "" : "modal"}
									>
										Excluir
									</button>
								</div>
							</>
						) : (
							<>
								<div className="modal-header">
									<div>
										<h3 className="modal-title">Consumidores por CPF</h3>
									</div>
									<button
										type="button"
										className="close text-danger"
										data-dismiss="modal"
										aria-label="Close"
									>
										<span aria-hidden="true">&times;</span>
									</button>
								</div>
								{this.state.lote ? (
									<div className="modal-body modal-xl pt-0">
										<p>
											Para adicionar vários CPF insira a lista de CPF contendo
											somente 1 por linha
										</p>
										<div className="form-group">
											<textarea
												className="form-control"
												id="exampleFormControlTextarea1"
												rows="10"
												placeholder="123.123.123-21&#10;123.123.123-21 &#10;123.123.123-21"
												onChange={this.change}
												onKeyPress={this.handleBreakLine}
											></textarea>
										</div>
										<div className="d-flex justify-content-center mt-3">
											<div className="">
												<button
													type="button"
													className="btn btn-light btn-new-width mr-2"
													onClick={() => this.setState({ lote: false })}
												>
													Voltar
												</button>
												<button
													type="button"
													className="btn btn-success btn-new-width"
													onClick={() => this.cpfSubmit()}
													data-dismiss={this.state.lote ? "" : "modal"}
												>
													Importar lote
												</button>
											</div>
										</div>
									</div>
								) : (
									<>
										<div className="modal-body voucher-cpf mb-2">
											<div className="modal-body d-flex flex-column flex-lg-row justify-content-between mt-2 mb-0">
												<div className="form-group autocomplete icon-right w-100 mr-4">
													<input
														className="form-control"
														name="pesquisa"
														placeholder="Busque por CPF"
														value={this.state.search}
														onChange={this.updateSearch.bind(this)}
													/>
													<i className="material-icons">search</i>
												</div>

												<div className="">
													<button
														className="btn btn-info "
														onClick={() => this.setState({ lote: true })}
													>
														Adicionar em lote
													</button>
												</div>
											</div>

											<div className="my-0 py-0">
												<div className="dropdown">
													<button
														id="massiveActions"
														type="button"
														className="btn btn-light dropdown-toggle"
														data-toggle="dropdown"
														aria-haspopup="true"
														aria-expanded="false"
													>
														Ações em massa
													</button>
													<div
														className="dropdown-menu"
														aria-labelledby="massiveActions"
													>
														<a
															className="dropdown-item text-danger"
															onClick={() => this.setState({ delete: true })}
														>
															Remover
														</a>
													</div>
												</div>
											</div>
											{this.state.cpfs.length > 0 ? (
												<div className="voucher-cpf-list">
													{this.state.loadingRequest ? (
														<Loading />
													) : (
														<>
															<table className="table">
																<thead>
																	<tr>
																		<th width="30">
																			<input
																				type="checkbox"
																				id="horns"
																				name="horns"
																				checked={this.state.allCheck}
																				onChange={() => this.selectAllItens()}
																			/>
																		</th>
																		<th scope="col text-info">CPF</th>
																		<th scope="col" className="text-right"></th>
																	</tr>
																</thead>
																<tbody>
																	{filteredCpfs.map((cpf, i) => (
																		<tr key={cpf.id}>
																			<td>
																				<input
																					type="checkbox"
																					id="horns"
																					name="horns"
																					onChange={() =>
																						this.selectItens(cpf.document, i)
																					}
																					checked={
																						this.state.selects.some(
																							(el) => el.id === cpf.document
																						)
																							? true
																							: false
																					}
																				/>
																			</td>
																			<td className="text-info">
																				{cpf.document}
																			</td>
																			<td className="text-right">
																				<button
																					type="button"
																					className="btn btn-link btn-sm  btn-icon text-right"
																					onClick={() => this.removeList(i)}
																				>
																					<i className="material-icons text-danger">
																						delete_outline
																					</i>
																				</button>
																			</td>
																		</tr>
																	))}
																</tbody>
															</table>
														</>
													)}
												</div>
											) : null}
										</div>
										<label className="ml-1">
											<strong>ADICIONAR NA LISTA</strong>
										</label>
										<div className="modal-body d-flex flex-column flex-lg-row justify-content-between my-1">
											<div className="w-100 mr-4 mb-3">
												<InputMask
													mask="999.999.999-99"
													className="form-control"
													type="text"
													placeholder="000.000.000-00"
													value={this.state.cpf}
													onChange={this.addCpf}
												/>
											</div>
											<div className="">
												<button
													type="button"
													className="btn btn-grey-80"
													onClick={() => this.cpfSubmit()}
												>
													Adicionar na lista
												</button>
											</div>
										</div>

										<div className="d-flex justify-content-center mt-0">
											<div className="">
												<button
													type="button"
													className="btn btn-light btn-new-width mr-2"
													data-dismiss="modal"
													style={{
														color: "#5A5E61",
														border: "1px solid #8D8D8D",
														backgroundColor: "transparent",
													}}
												>
													Cancelar
												</button>
												<button
													type="button"
													className="btn btn-success btn-new-width"
													onClick={() => this.save()}
													data-dismiss={"modal"}
												>
													Salvar
												</button>
											</div>
										</div>
									</>
								)}
							</>
						)}
					</div>
				</div>
			</div>
		);
	}
}

export default Cpf;
