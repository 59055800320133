import * as axios from "axios";
import { toast } from "react-toastify";
require("dotenv/config");

//Temos duas APIs, por isso fazemos duas vezes as validaçoes, e criamos esse componente global para as requisiçoes.
let API = axios.create({
	baseURL: process.env.REACT_APP_API_ENDPOINT, //setamos a API do ambiente pelo env.
	responseType: "json",
});

API.interceptors.request.use(async function (config) {
	const token = localStorage.getItem("token"); //pegamos o token ativo no localstorage
	const role = localStorage.getItem("role");
	config.headers.Authorization = token ? `Bearer ${token}` : "";
	if (!role) {
		localStorage.clear();
	}
	return config;
});

API.interceptors.response.use(
	(response) => response,
	(error) => {
		// whatever you want to do with the error

		//verificamos se o usuario nao caiu em 401 (Sem autorizaçao)
		if (error?.response?.status === 401) {
			//Sem autorizacao damos uma mensagem e redirecionamos o usuario para o login, limpando o Storage de token.
			if (
				window.location.pathname !== "/login" ||
				window.location.pathname !== "/"
			) {
				// setTimeout(
				//     function () {
				localStorage.removeItem("token");
				window.location.href = "/login/" + btoa(window.location.pathname);
				toast.error(
					"Sua sessão expirou, estamos redirecionando para tela de Login. 😃"
				);
				//     }
				//         .bind(this),
				//     1000
				// )
			}
		}
		throw error;
	}
);

//Igual a parte de cima, porém com o endpoint da V2

const createApiInstance = (baseURL, serverUrlFunction) => {
	const apiInstance = axios.create({
		baseURL: baseURL,
		responseType: "json",
	});

	apiInstance.interceptors.request.use(async function (config) {
		const token = localStorage.getItem("token");
		config.baseURL = await serverUrlFunction();
		config.headers.Authorization = token ? `Bearer ${token}` : "";
		return config;
	});

	apiInstance.interceptors.response.use(
		(response) => response,
		(error) => {
			if (error?.response?.status === 401) {
				if (
					window.location.pathname !== "/login" ||
					window.location.pathname !== "/"
				) {
					localStorage.removeItem("token");
					window.location.href = "/login/" + btoa(window.location.pathname);
					toast.error(
						"Sua sessão expirou, estamos redirecionando para tela de Login. 😃"
					);
				}
			}
			throw error;
		}
	);

	return apiInstance;
};

const APIV1ServerUrl = () => {
	if (
		localStorage.getItem("UseCatalogProcessor") !== "true" ||
		!localStorage.getItem("token")
	) {
		return process.env.REACT_APP_APIV1_ENDPOINT;
	} else {
		return process.env.REACT_APP_APIV1_ENDPOINT_CATALOG_PROCESSOR;
	}
};

const APIV2ServerUrl = () => {
	if (
		localStorage.getItem("UseCatalogProcessor") !== "true" ||
		!localStorage.getItem("token")
	) {
		return process.env.REACT_APP_APIV2_ENDPOINT;
	} else {
		return process.env.REACT_APP_APIV2_ENDPOINT_CATALOG_PROCESSOR;
	}
};

const APIV1 = createApiInstance(
	process.env.REACT_APP_APIV1_ENDPOINT,
	APIV1ServerUrl
);

const APIV2 = createApiInstance(
	process.env.REACT_APP_APIV2_ENDPOINT,
	APIV2ServerUrl
);

let APIIMPORT = axios.create({
	baseURL: process.env.REACT_APP_API_IMPORT_PROCESSOR,
	responseType: "json",
});
APIIMPORT.interceptors.request.use(async function (config) {
	const token = localStorage.getItem("token");
	const role = localStorage.getItem("role");
	config.headers.Authorization = token ? `Bearer ${token}` : "";
	if (!role) {
		localStorage.clear();
	}
	return config;
});

APIIMPORT.interceptors.response.use(
	(response) => response,
	(error) => {
		if (error?.response?.status === 401) {
			if (
				window.location.pathname !== "/login" ||
				window.location.pathname !== "/"
			) {
				localStorage.removeItem("token");
				window.location.href = "/login/" + btoa(window.location.pathname);
				toast.error(
					"Sua sessão expirou, estamos redirecionando para tela de Login. 😃"
				);
			}
		}
		throw error;
	}
);

export { API, APIIMPORT, APIV2, APIV2ServerUrl, APIV1 };
