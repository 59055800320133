export enum DocumentType {
	None = "None",
	Cpf = "Cpf",
	Cnpj = "Cnpj",
}

export enum NumericDocumentType {
	None = 0,
	Cpf = 1,
	Cnpj = 2,
}

export const mapNumericToStringDocumentType: Record<
	NumericDocumentType,
	DocumentType
> = {
	[NumericDocumentType.None]: DocumentType.None,
	[NumericDocumentType.Cpf]: DocumentType.Cpf,
	[NumericDocumentType.Cnpj]: DocumentType.Cnpj,
};
