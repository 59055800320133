import React, { ChangeEvent, useState } from "react";
import { SelectChangeEvent, Stack } from "@mui/material";
import RetailerUpdateButton from "../components/RetailerUpdateButton";
import { handleGenericChange } from "../../../../infrastructure/utils/handleGenericChange";
import { LayoutProfile } from "../../../../domain/schemas/layoutProfile/LayoutProfile";
import IdentityWhiteLabel from "./IdentityWhiteLabel";

type Props = {
	layoutProfile?: LayoutProfile;
	isLoadingUpdate: boolean;
	upsertLayoutProfiles: (
		layoutProfilesData: LayoutProfile,
		isCreate: boolean
	) => Promise<void>;
};

export default function IdentityConfigurations({
	layoutProfile,
	isLoadingUpdate,
	upsertLayoutProfiles,
}: Props) {
	const [identityConfig, setIdentityConfig] = useState<LayoutProfile>({
		...(layoutProfile && {
			clientId: layoutProfile.clientId,
			id: layoutProfile.id,
		}),
		dashboardHorizontalDarkImageUrl:
			layoutProfile?.dashboardHorizontalDarkImageUrl ?? "",
		dashboardHorizontalLightImageUrl:
			layoutProfile?.dashboardHorizontalLightImageUrl ?? "",
		dashboardPrimaryColor: layoutProfile?.dashboardPrimaryColor ?? "",
		dashboardSecondaryColor: layoutProfile?.dashboardSecondaryColor ?? "",
	});

	const handleChange = (
		event: ChangeEvent<HTMLInputElement> | SelectChangeEvent<string>,
		checked?: boolean
	) => {
		handleGenericChange<LayoutProfile>(setIdentityConfig, event, checked);
	};

	const handleColorAndImageChange = (
		inputName: keyof LayoutProfile,
		value: string
	): void => {
		setIdentityConfig((prevState) => ({ ...prevState, [inputName]: value }));
	};

	const handleSubmit = (): void => {
		const isCreate = !layoutProfile;
		upsertLayoutProfiles(identityConfig, isCreate);
	};

	return (
		<>
			<Stack gap={2}>
				<IdentityWhiteLabel
					identityConfig={identityConfig}
					onChange={handleChange}
					onColorAndImageChange={handleColorAndImageChange}
				/>
			</Stack>
			<RetailerUpdateButton
				isLoadingUpdate={isLoadingUpdate}
				onSubmit={handleSubmit}
			/>
		</>
	);
}
