import React, { useState } from "react";
import ContentLoader from "react-content-loader";
import { useOrders } from "../../providers/OrdersContext";
import { formatDocument } from "../../../../infrastructure/utils/FormatMask";
import { OrderModel } from "../../../../domain/models/commerce/OrderModel";
import { i18n } from "../../../../translate/i18n";
import BuyerEditDialog from "../../Dialogs/BuyerEditDialog";
import {
	mapNumericToStringDocumentType,
	NumericDocumentType,
} from "../../../../domain/enums/DocumentType";
import { applyMask } from "../../../../infrastructure/utils/FormatUtils";
import { countryMaskRules } from "../../../../components/InternationalPhoneInput/CountryMaskRules";
import CustomTypography from "../../../../MUICustomComponents/CustomTypography";
import { Stack } from "@mui/material";
import { InputMasks } from "../../../../infrastructure/utils/InputMasks";

type TemporaryContextTypes = {
	orderModel: OrderModel;
	loading: boolean;
	getOrders: Function;
};

export default function ClientInfo() {
	const { orderModel, loading, getOrders } =
		useOrders() as TemporaryContextTypes;
	const [isBuyerEditOpen, setIsBuyerEditOpen] = useState<boolean>(false);

	const updateBuyerCallback = (): void => {
		getOrders(orderModel.id);
		setIsBuyerEditOpen(false);
	};

	const skeleton = () => {
		const skele = [0, 1, 2, 4, 5, 6];
		return skele.map((key) => (
			<ContentLoader
				key={key}
				speed={2}
				width={400}
				height={20}
				backgroundColor="#f3f3f3"
				foregroundColor="#ecebeb"
				viewBox="0 0 1100 60"
				style={{ width: "100%" }}
			>
				<rect x="5" y="25" rx="3" ry="3" width="400" height="100" />
				<rect x="410" y="26" rx="3" ry="3" width="400" height="100" />
			</ContentLoader>
		));
	};

	return (
		<div className="card box-one">
			{loading ? (
				skeleton()
			) : (
				<>
					<div className="box-title">
						<h2 className="card-title">
							{i18n.t("legacyClientInfo.Title").toString()}
						</h2>
						<a
							className="tracking-edit-link"
							style={{ paddingTop: "5px" }}
							onClick={() => setIsBuyerEditOpen(true)}
						>
							{i18n.t("legacyClientInfo.EditClientInfo").toString()}
						</a>
					</div>

					{orderModel.buyer.fullName && (
						<span className="card-subtitle">
							<strong>{orderModel.buyer.fullName}</strong>
						</span>
					)}
					{orderModel.buyer.documentType !== NumericDocumentType.None && (
						<span className="card-subtitle">
							<strong>
								{i18n
									.t(
										`legacyClientInfo.${
											mapNumericToStringDocumentType[
												orderModel.buyer.documentType
											]
										}`
									)
									.toString()}{" "}
							</strong>
							{formatDocument(
								mapNumericToStringDocumentType[orderModel.buyer.documentType],
								orderModel.buyer.document
							)}
						</span>
					)}
					{orderModel.buyer.email && (
						<span className="card-subtitle">
							<strong className="text-nowrap">
								{i18n.t("legacyClientInfo.Email").toString()}{" "}
							</strong>
							{orderModel.buyer.email}
						</span>
					)}
					{orderModel.buyer.phoneNumber && (
						<span className="card-subtitle">
							<strong>
								{i18n.t("legacyClientInfo.PhoneNumber").toString()}{" "}
							</strong>
							{applyMask(
								orderModel.buyer.phoneNumber,
								countryMaskRules.BR.maskByLengthRule(
									orderModel.buyer.phoneNumber
								)
							)}
						</span>
					)}
					{orderModel.buyer.companyName && (
						<span className="card-subtitle">
							<strong>
								{i18n.t("legacyClientInfo.CompanyName").toString()}{" "}
							</strong>
							{orderModel.buyer.companyName}
						</span>
					)}
					{orderModel.buyer.stateInscription && (
						<span className="card-subtitle">
							<strong>
								{i18n.t("legacyClientInfo.StateInscription").toString()}{" "}
							</strong>
							{orderModel.buyer.stateInscription}
						</span>
					)}
					{orderModel.buyer.address && (
						<>
							<CustomTypography variant="secondaryTitle" isBold sx={{ my: 1 }}>
								{i18n.t("legacyClientInfo.Address").toString()}
							</CustomTypography>
							<Stack direction="column" spacing={1}>
								<CustomTypography variant="default">
									{orderModel.buyer.address.street},{" "}
									{orderModel.buyer.address.number},{" "}
									{orderModel.buyer.address.complement}
								</CustomTypography>
								<CustomTypography variant="default">
									{orderModel.buyer.address.neighborhood},{" "}
									{orderModel.buyer.address.city},{" "}
									{orderModel.buyer.address.state}
								</CustomTypography>
								{orderModel.buyer.address.reference && (
									<CustomTypography variant="default">
										<strong>
											{i18n.t("legacyClientInfo.Reference").toString()}{" "}
										</strong>
										{orderModel.buyer.address.reference}
									</CustomTypography>
								)}
								{orderModel.buyer.address.zipCode && (
									<CustomTypography variant="default">
										<strong>
											{i18n.t("legacyClientInfo.ZipCode").toString()}{" "}
										</strong>
										{applyMask(
											orderModel.buyer.address.zipCode,
											InputMasks.ZipCode
										)}
									</CustomTypography>
								)}
							</Stack>
						</>
					)}
				</>
			)}
			{orderModel && (
				<BuyerEditDialog
					orderModel={orderModel}
					isDialogOpen={isBuyerEditOpen}
					setIsDialogOpen={setIsBuyerEditOpen}
					updateCallback={updateBuyerCallback}
				/>
			)}
		</div>
	);
}
