import { Box, Grid, Tab, Tabs } from "@mui/material";
import React from "react";
import ERDetailsService from "./ERDetailsService";
import ERGeneralInfo from "./components/ERGeneralInfo";
import CustomSkeleton from "../../../MUICustomComponents/CustomSkeleton";
import { Switch, Route, Link } from "react-router-dom";
import ERHistory from "./ERHistory";
import ERTracking from "./components/ERTracking";
import ERTimeline from "./components/ERTimeline";
import ERClient from "./components/ERClient";
import ERItems from "./components/ERItems";
import ERData from "./components/ERData";

export default function ERDetails() {
	const {
		isLoading,
		erOrder,
		simplifiedOrderModel,
		routeParams,
		currentTab,
		handleTabChange,
	} = ERDetailsService();

	const renderDetailsTab = (): JSX.Element => {
		return (
			<Grid
				container
				spacing={2}
				sx={{
					".card": { height: "100%" },
					".MuiTypography-body1": {
						lineHeight: "19px",
					},
				}}
			>
				<Grid item xs={4}>
					<ERGeneralInfo data={erOrder} isLoading={isLoading} />
				</Grid>
				<Grid item xs={4}>
					<ERData data={erOrder} isLoading={isLoading} />
				</Grid>
				<Grid item xs={4}>
					<ERClient data={erOrder} isLoading={isLoading} />
				</Grid>
				<Grid item xs={8}>
					<ERTimeline data={erOrder} isLoading={isLoading} />
				</Grid>
				<Grid item xs={4}>
					<ERTracking data={erOrder} isLoading={isLoading} />
				</Grid>
				<Grid item xs={12}>
					<Box className="card" p={3}>
						{isLoading || !erOrder || !simplifiedOrderModel ? (
							<CustomSkeleton quantity={6} height={30} />
						) : (
							<ERItems
								data={erOrder}
								simplifiedOrderModel={simplifiedOrderModel}
								isLoading={isLoading}
							/>
						)}
					</Box>
				</Grid>
			</Grid>
		);
	};

	return (
		<>
			<Box sx={{ borderBottom: 1, borderColor: "divider" }} mb={2}>
				<Tabs
					value={currentTab}
					onChange={handleTabChange}
					role="navigation"
					sx={{ ".MuiTab-root": { textTransform: "none", color: "inherit" } }}
				>
					<Tab
						label="Detalhes"
						component={Link}
						to={`/exchangeandreturn/${routeParams.page}/${routeParams.id}`}
					/>
					<Tab
						label="Histórico"
						component={Link}
						to={`/exchangeandreturn/${routeParams.page}/${routeParams.id}/history`}
					/>
				</Tabs>
			</Box>
			<Switch>
				<Route
					path="/exchangeandreturn/:page/:id"
					exact
					children={renderDetailsTab}
				/>
				<Route
					path="/exchangeandreturn/:page/:id/history"
					exact
					children={<ERHistory data={erOrder} isLoading={isLoading} />}
				/>
			</Switch>
		</>
	);
}
