import React from "react";
import InternationalPhoneInputService, {
	CountryDataType,
} from "./InternationalPhoneInputService";
import { InputAdornment, MenuItem, Select } from "@mui/material";
import CustomInput from "../../MUICustomComponents/CustomInput";

type Props = {
	inputName: string;
	title: string;
	phoneNumber: string | null;
	onPhoneChange: (
		phone: string,
		currentCountry: CountryDataType | null
	) => void;
	error?: boolean;
	helperText?: string[];
	disabled?: boolean;
};

export default function InternationalPhoneInput({
	inputName,
	title,
	phoneNumber,
	onPhoneChange,
	error = false,
	helperText = [],
	disabled = false,
}: Props) {
	const {
		countriesRef,
		countryInput,
		currentCountry,
		handlePhoneChange,
		handleSelectChange,
	} = InternationalPhoneInputService(phoneNumber, onPhoneChange);

	return (
		<CustomInput
			name={inputName}
			title={title}
			placeholder={title}
			value={countryInput}
			onChange={handlePhoneChange}
			error={error}
			helperText={helperText}
			disabled={disabled}
			startAdornmentElement={
				<>
					<Select
						MenuProps={{
							style: {
								height: "300px",
								width: "360px",
								top: "10px",
								left: "-34px",
							},
							transformOrigin: {
								vertical: "top",
								horizontal: "left",
							},
						}}
						sx={{
							width: "max-content",
							// Remove default outline (display only on focus)
							fieldset: {
								display: "none",
							},
							'&.Mui-focused:has(div[aria-expanded="false"])': {
								fieldset: {
									display: "block",
									border: "none",
								},
							},
							// Update default spacing
							".MuiSelect-select": {
								padding: "8px",
								paddingRight: "24px !important",
							},
							svg: {
								right: 0,
							},
						}}
						value={currentCountry?.code || ""}
						onChange={handleSelectChange}
						displayEmpty
						renderValue={(countryCode) => {
							if (!countryCode) return "-";
							return (
								<img
									width="20"
									src={`https://flagcdn.com/w20/${countryCode.toLowerCase()}.png`}
								/>
							);
						}}
					>
						{countriesRef.current.map((option) => (
							<MenuItem key={option.code} value={option.code}>
								<img
									width="20"
									src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
									style={{ marginRight: "8px" }}
								/>
								{option.label} {option.phone}
							</MenuItem>
						))}
					</Select>
					{currentCountry && (
						<InputAdornment position="start">
							{currentCountry.phone}
						</InputAdornment>
					)}
				</>
			}
		/>
	);
}
