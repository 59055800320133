import { Box, Stack } from "@mui/material";
import React from "react";
import CustomTypography from "../../../../MUICustomComponents/CustomTypography";
import { TCOrder } from "../../../../domain/models/troquecommerce/TCOrder";
import CustomSkeleton from "../../../../MUICustomComponents/CustomSkeleton";
import { formatCurrency } from "../../../../infrastructure/utils/FormatUtils";
import { formatDate } from "../../../../infrastructure/utils/FormatMask";

type Props = { data: TCOrder | null; isLoading: boolean };

export default function ERData({ data, isLoading }: Props) {
	const renderReverseCoupon = (): JSX.Element => {
		if (!data || !data.reverse_coupon) return <></>;
		return (
			<>
				<CustomTypography variant="secondaryTitle" isBold sx={{ my: 1 }}>
					Cupom
				</CustomTypography>
				<Stack direction="column" spacing={0.5}>
					<CustomTypography variant="default" color="grey60">
						<strong>Código do cupom: </strong>
						{data.reverse_coupon.code}
					</CustomTypography>
					<CustomTypography variant="default" color="grey60">
						<strong>Id no Ecommerce: </strong>
						{data.reverse_coupon.coupon_ecommerce_id}
					</CustomTypography>
					<CustomTypography variant="default" color="grey60">
						<strong>Valor: </strong>
						{formatCurrency(data.reverse_coupon.value)}
					</CustomTypography>
					<CustomTypography variant="default" color="grey60">
						<strong>Validade: </strong>
						{formatDate(data.reverse_coupon.validity)}
					</CustomTypography>
				</Stack>
			</>
		);
	};

	const renderReversePayment = (): JSX.Element => {
		if (!data || !data.reverse_payment) return <></>;
		return (
			<>
				<CustomTypography variant="secondaryTitle" isBold sx={{ my: 1 }}>
					Reembolso
				</CustomTypography>
				<Stack direction="column" spacing={0.5}>
					<CustomTypography variant="default" color="grey60">
						<strong>Tipo do pagamento: </strong>
						{data.reverse_payment.action}
					</CustomTypography>
					<CustomTypography variant="default" color="grey60">
						<strong>Valor: </strong>
						{formatCurrency(data.reverse_payment.value)}
					</CustomTypography>
					<CustomTypography variant="default" color="grey60">
						<strong>Data do pagamento: </strong>
						{formatDate(data.reverse_payment.created_at)}
					</CustomTypography>
				</Stack>
			</>
		);
	};

	return (
		<Box className="card" p={3}>
			<CustomTypography variant="secondaryTitle" isBold marginBottom={1}>
				Dados da troca/devolução
			</CustomTypography>
			{isLoading || !data ? (
				<CustomSkeleton quantity={5} height={20} />
			) : (
				<>
					<Stack direction="column" spacing={0.5}>
						<CustomTypography variant="default" color="grey60">
							<strong>Valor total do pedido: </strong>{" "}
							{formatCurrency(data.price)}
						</CustomTypography>
						<CustomTypography variant="default" color="grey60">
							<strong>Valor do vale-compras: </strong>{" "}
							{formatCurrency(data.exchange_value)}
						</CustomTypography>
						<CustomTypography variant="default" color="grey60">
							<strong>Valor do reembolso: </strong>{" "}
							{formatCurrency(data.refund_value)}
						</CustomTypography>
					</Stack>
					{data.reverse_coupon && renderReverseCoupon()}
					{data.reverse_payment && renderReversePayment()}
					{data.is_second_request ||
						(data.is_second_reverse && (
							<>
								<CustomTypography
									variant="secondaryTitle"
									isBold
									sx={{ my: 1 }}
								>
									Detalhes
								</CustomTypography>
								<Stack direction="column" spacing={0.5}>
									{data.is_second_request && (
										<CustomTypography variant="default" color="grey60">
											- Segunda solicitação de troca
										</CustomTypography>
									)}
									{data.is_second_reverse && (
										<CustomTypography variant="default" color="grey60">
											- Solicitação de troca de um pedido que utilizou
											vale-compras gerado pela Troquecommerce. (Troca da troca)
										</CustomTypography>
									)}
								</Stack>
							</>
						))}
				</>
			)}
		</Box>
	);
}
