import { z } from "zod";

export const layoutProfileSchema = z.object({
	id: z.string().uuid().optional(),
	clientId: z.string().optional(),
	dashboardPrimaryColor: z.string(),
	dashboardSecondaryColor: z.string(),
	dashboardHorizontalDarkImageUrl: z.string().nullable(),
	dashboardHorizontalLightImageUrl: z.string().nullable(),
});

export type LayoutProfile = z.infer<typeof layoutProfileSchema>;
