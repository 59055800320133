export enum BuyerInputs {
	FullName = "fullName",
	Document = "document",
	DocumentType = "documentType",
	Email = "email",
	PhoneNumber = "phoneNumber",
	CompanyName = "companyName",
	StateInscription = "stateInscription",
	ZipCode = "zipCode",
	Street = "street",
	Number = "number",
	Complement = "complement",
	Neighborhood = "neighborhood",
	City = "city",
	State = "state",
	Reference = "reference",
}
