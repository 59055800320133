import {
	Breakpoint,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	SxProps,
	Theme,
} from "@mui/material";
import React, { Dispatch, ReactNode, SetStateAction } from "react";
import CustomTypography from "./CustomTypography";
import { i18n } from "../translate/i18n";
import LoadingButtonIcon from "../components/LoadingButtonIcon";
import { ActionButton } from "../domain/types/ActionButton";
import CustomIcon from "./CustomIcon";
import { CloseRounded } from "@mui/icons-material";

type DialogInfo = {
	title: string;
	subtitle?: string;
	titleStyles?: SxProps<Theme>;
};
type DialogStyles = {
	titleVariant: "title" | "secondaryTitle";
	maxWidth?: Breakpoint;
	customMaxWidth?: string;
	actionStyles?: SxProps<Theme>;
	dialogTitleStyles?: SxProps<Theme>;
	contentStyles?: SxProps<Theme>;
};

type Props = {
	children?: ReactNode;
	isOpen: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
	dialogInfo?: DialogInfo;
	actionButton?: ActionButton;
	dialogStyles?: DialogStyles;
	closeCallback?: Function;
	showCancelButton?: boolean;
	showCloseButton?: boolean;
	customActions?: ReactNode;
};

export default function CustomDialog({
	children,
	isOpen,
	setIsOpen,
	dialogInfo,
	actionButton,
	dialogStyles = {
		titleVariant: "secondaryTitle",
	},
	closeCallback,
	showCancelButton = true,
	showCloseButton = true,
	customActions,
}: Props) {
	const handleOnClose = () => {
		setIsOpen(false);
		if (closeCallback) closeCallback();
	};

	const dialogDefaultFooter = () => {
		if (!actionButton) return <></>;
		return (
			<>
				{showCancelButton && (
					<Button variant="contained" color="light" onClick={handleOnClose}>
						{i18n.t(`buttons.General.BtnCancel`).toString()}
					</Button>
				)}
				<Button
					variant={actionButton.variant}
					color={actionButton.color}
					onClick={() => actionButton.onClick()}
					disabled={actionButton.isDisabled || actionButton.isLoading}
				>
					{actionButton.isLoading ? <LoadingButtonIcon /> : actionButton.text}
				</Button>
			</>
		);
	};

	return (
		<Dialog
			open={isOpen}
			onClose={handleOnClose}
			{...(dialogStyles?.maxWidth && {
				fullWidth: true,
				maxWidth: dialogStyles.maxWidth,
			})}
			{...(dialogStyles?.customMaxWidth && {
				fullWidth: true,
				sx: {
					"& .MuiDialog-container": {
						"& .MuiPaper-root": {
							maxWidth: dialogStyles.customMaxWidth,
						},
					},
				},
			})}
		>
			{showCloseButton && (
				<IconButton
					sx={{ position: "absolute", top: "16px", right: "16px" }}
					onClick={handleOnClose}
				>
					<CustomIcon variant="default" icon={<CloseRounded />} />
				</IconButton>
			)}
			{dialogInfo && (
				<DialogTitle sx={{ p: 3, pb: 2, ...dialogStyles.dialogTitleStyles }}>
					<CustomTypography
						variant={dialogStyles.titleVariant}
						marginBottom={0.5}
						{...(dialogInfo?.titleStyles && { sx: dialogInfo.titleStyles })}
					>
						{dialogInfo.title}
					</CustomTypography>
					{dialogInfo.subtitle && (
						<CustomTypography variant="default">
							{dialogInfo.subtitle}
						</CustomTypography>
					)}
				</DialogTitle>
			)}
			{children && (
				<DialogContent sx={{ px: 3, pb: 2, ...dialogStyles?.contentStyles }}>
					{children}
				</DialogContent>
			)}
			<DialogActions sx={{ px: 3, pb: 3, ...dialogStyles?.actionStyles }}>
				{customActions ? customActions : dialogDefaultFooter()}
			</DialogActions>
		</Dialog>
	);
}
