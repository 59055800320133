import { useEffect, useState } from "react";
import { retailerManagementApi } from "../../../infrastructure/api/RetailerManagementApi";
import { RetailerConfiguration } from "../../../domain/dtos/retailerManagement/retailerConfigurations/RetailerConfiguration";
import NewToastComponent from "../../../components/NewToastComponent";
import { i18n } from "../../../translate/i18n";
import { GeneralConfig } from "./types/GeneralConfig";
import { AppConfig } from "./types/AppConfig";
import { LayoutProfile } from "../../../domain/schemas/layoutProfile/LayoutProfile";

export default function RetailerConfigService() {
	const [retailerConfigurations, setRetailerConfigurations] =
		useState<RetailerConfiguration>();
	const [isLoadingUpdate, setIsLoadingUpdate] = useState<boolean>(false);
	const [layoutProfile, setLayoutProfile] = useState<LayoutProfile>();
	const [isLoadingInitialData, setIsLoadingInitialData] =
		useState<boolean>(true);

	useEffect(() => {
		const fetchInitialData = async () => {
			await Promise.all([getRetailerConfiguration(), getLayoutProfiles()]);
			setIsLoadingInitialData(false);
		};

		fetchInitialData();
	}, []);

	const getRetailerConfiguration = async (): Promise<void> => {
		try {
			const data: RetailerConfiguration =
				await retailerManagementApi.getRetailerConfiguration();
			setRetailerConfigurations(data);
			localStorage.setItem("configurations", JSON.stringify(new Array(data)));
		} catch {
			NewToastComponent({
				status: "error",
				title: i18n.t("errorMessages.Default"),
				message: i18n.t("errorMessages.TryAgainLater"),
			});
			return;
		}
		setIsLoadingUpdate(false);
	};

	const updateRetailerConfig = async (
		configPart: GeneralConfig | AppConfig
	): Promise<void> => {
		setIsLoadingUpdate(true);
		if (!retailerConfigurations) return;
		const updateRetailerBody = { ...retailerConfigurations, ...configPart };
		const success = await retailerManagementApi.upsertRetailerConfiguration(
			updateRetailerBody
		);
		if (!success) {
			NewToastComponent({
				status: "error",
				title: i18n.t("errorMessages.Default"),
				message: i18n.t("errorMessages.TryAgainLater"),
			});
			setIsLoadingUpdate(false);
			return;
		}
		NewToastComponent({
			status: "success",
			title: i18n.t("retailerConfig.UpdateSuccess"),
		});
		getRetailerConfiguration();
	};

	const getLayoutProfiles = async (): Promise<void> => {
		// To be removed when layoutProfiles is migrated to retailerConfig
		try {
			const data = await retailerManagementApi.getLayoutProfile();
			setLayoutProfile(data);
		} catch (error) {
			NewToastComponent({
				status: "error",
				title: i18n.t("errorMessages.Default"),
				message: i18n.t("errorMessages.TryAgainLater"),
			});
		}
		setIsLoadingUpdate(false);
	};

	const upsertLayoutProfiles = async (
		layoutProfilesData: LayoutProfile,
		isCreate: boolean
	): Promise<void> => {
		// To be removed when layoutProfiles is migrated to retailerConfig
		setIsLoadingUpdate(true);
		const success = isCreate
			? await retailerManagementApi.createLayoutProfile(layoutProfilesData)
			: await retailerManagementApi.updateLayoutProfile(layoutProfilesData);

		if (!success) {
			NewToastComponent({
				status: "error",
				title: i18n.t("errorMessages.Default"),
				message: i18n.t("errorMessages.TryAgainLater"),
			});
			setIsLoadingUpdate(false);
			return;
		}
		NewToastComponent({
			status: "success",
			title: i18n.t("retailerConfig.UpdateSuccess"),
		});
		getLayoutProfiles();
	};

	return {
		retailerConfigurations,
		layoutProfile,
		isLoadingInitialData,
		isLoadingUpdate,
		updateRetailerConfig,
		upsertLayoutProfiles,
	};
}
