import { Box, Stack } from "@mui/material";
import React from "react";
import CustomTypography from "../../../../MUICustomComponents/CustomTypography";
import { paletteObject } from "../../../../theme/foundations/palette";
import { formatDate } from "../../../../infrastructure/utils/FormatMask";
import { TCOrder } from "../../../../domain/models/troquecommerce/TCOrder";
import CustomSkeleton from "../../../../MUICustomComponents/CustomSkeleton";

type Props = { data: TCOrder | null; isLoading: boolean };

export default function ERGeneralInfo({ data, isLoading }: Props) {
	return (
		<Box
			className="card"
			p={3}
			sx={{ backgroundColor: paletteObject.grey90.main }}
		>
			<Stack direction="row" justifyContent="space-between" mb={1}>
				<CustomTypography variant="secondaryTitle" isBold color="white">
					Solicitação
				</CustomTypography>
				<span
					className="badge badge-grey-60 pb-1"
					style={{
						color: paletteObject.white.main,
						minWidth: "53px",
						height: "24px",
						fontSize: "12px",
						fontWeight: 700,
						textAlign: "center",
						margin: 0,
						padding: "3px 8px 0 8px",
						borderRadius: "5px",
					}}
				>
					{isLoading || !data ? (
						<CustomSkeleton quantity={1} height={20} />
					) : (
						`Nº ${data.ecommerce_number}`
					)}
				</span>
			</Stack>
			{isLoading || !data ? (
				<CustomSkeleton quantity={5} height={20} />
			) : (
				<Stack direction="column" spacing={0.5}>
					<CustomTypography variant="default" color="white">
						<strong>Data de criação: </strong>
						{formatDate(data.created_at)}
					</CustomTypography>
					<CustomTypography variant="default" color="white">
						<strong>Últ. atualização: </strong>
						{formatDate(data.updated_at)}
					</CustomTypography>
					<CustomTypography variant="default" color="white">
						<strong>Status: </strong>
						{data.status}
					</CustomTypography>
					<CustomTypography variant="default" color="white">
						<strong>Tipo de solicitação: </strong>
						{data.reverse_type}
					</CustomTypography>
					<CustomTypography variant="default" color="white">
						<strong>Modalidade: </strong>
						{data.is_collect ? "Coleta" : "Postagem"}
					</CustomTypography>
				</Stack>
			)}
		</Box>
	);
}
