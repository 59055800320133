import { ChangeEvent, useState } from "react";
import { AutocompleteStateView } from "../../domain/views/legacyApi/AutocompleteStateView";
import {
	FormErrors,
	FormValidations,
	FormValidator,
	InputValidator,
} from "../../infrastructure/utils/FormUtils";
import { AddresseeInputs } from "./AddresseeInputs";
import { cpCommerceManagementApi } from "../../infrastructure/api/CPCommerceManagement";
import { AddresseeUpdateModel } from "../../domain/models/commerce/AddresseeUpdateModel";
import NewToastComponent from "../../components/NewToastComponent";
import { i18n } from "../../translate/i18n";
import { AddresseeFormData } from "./AddresseeFormData";
import { OrderModel } from "../../domain/models/commerce/OrderModel";
import { normalizeDigitsOnly } from "../../infrastructure/utils/StringUtils";
import { ZipCodeDto } from "../../domain/dtos/SalesApi/ZipCodeDto";

export default function UpdateAddresseeDialogService(
	orderModel: OrderModel,
	callback: Function
) {
	const [isLoadingUpdate, setIsLoadingUpdate] = useState<boolean>(false);
	const [currentState, setCurrentState] =
		useState<AutocompleteStateView | null>(null);
	const [isLoadingZipCode, setIsLoadingZipCode] = useState<boolean>(false);
	const [deliveryData, setDeliveryData] = useState<AddresseeFormData>(
		orderModel.shippingData.deliveryAddress! && {
			fullName: orderModel.buyer?.fullName ?? "",
			street: orderModel.shippingData.deliveryAddress.street,
			number: orderModel.shippingData.deliveryAddress.number,
			complement: orderModel.shippingData.deliveryAddress.complement,
			neighborhood: orderModel.shippingData.deliveryAddress.neighborhood,
			city: orderModel.shippingData.deliveryAddress.city,
			state: orderModel.shippingData.deliveryAddress.state,
			zipCode: orderModel.shippingData.deliveryAddress.zipCode,
		}
	);
	const [formErrors, setFormErrors] = useState<FormErrors>({});
	const formValidationMapper: FormValidations = {
		[AddresseeInputs.FullName]: InputValidator.isRequired,
		[AddresseeInputs.Street]: InputValidator.isRequired,
		[AddresseeInputs.ZipCode]: InputValidator.isZipCodeValid,
		[AddresseeInputs.Number]: InputValidator.isRequired,
		[AddresseeInputs.Neighborhood]: InputValidator.isRequired,
		[AddresseeInputs.City]: InputValidator.isRequired,
		[AddresseeInputs.State]: InputValidator.isRequired,
	};

	const handleDeliveryInfoChange = (e: ChangeEvent<HTMLInputElement>): void => {
		let { name, value } = e.target;

		if (formValidationMapper[name] !== undefined) {
			const newErrors: string[] = formValidationMapper[name](value);
			setFormErrors((prevState) => ({ ...prevState, [name]: newErrors }));
		}

		setDeliveryData((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const handleZipCodeChange = (
		value: string,
		zipCodeResponse?: ZipCodeDto | false
	) => {
		const newErrors: string[] = formValidationMapper[AddresseeInputs.ZipCode](
			value,
			zipCodeResponse
		);
		setFormErrors((prevState) => ({
			...prevState,
			[AddresseeInputs.ZipCode]: newErrors,
		}));
		setDeliveryData((prevState) => ({
			...prevState,
			zipCode: value,
			...(zipCodeResponse && {
				city: zipCodeResponse.city,
				complement: "",
				neighborhood: zipCodeResponse.neighborhood,
				number: "",
				state: zipCodeResponse.state,
				street: zipCodeResponse.street,
			}),
		}));
	};

	const handleAutocompleteChange = (
		value: AutocompleteStateView | null
	): void => {
		setCurrentState(value);
		const stateCode = value?.code ?? "";
		setDeliveryData((prevState) => ({
			...prevState,
			[AddresseeInputs.State]: stateCode,
		}));
		const errMessages: string[] =
			formValidationMapper[AddresseeInputs.State](stateCode);
		setFormErrors((prevState) => ({
			...prevState,
			[AddresseeInputs.State]: errMessages,
		}));
	};

	const mapAddresseeFormDataToAddresseeUpdateModel = (
		formData: AddresseeFormData
	): AddresseeUpdateModel => {
		const addresseeObject: AddresseeUpdateModel = {
			name: formData.fullName,
			address: {
				city: formData.city,
				complement: formData.complement,
				neighborhood: formData.neighborhood,
				number: formData.number,
				state: formData.state,
				street: formData.street,
				zipCode: normalizeDigitsOnly(formData.zipCode),
			},
		};
		return addresseeObject;
	};

	const handleSubmit = (): void => {
		let errors: FormErrors = {};
		for (const [key, value] of Object.entries(deliveryData)) {
			if (formValidationMapper[key] === undefined) continue;
			const errorMessages: string[] = formValidationMapper[key](value);
			errors[key] = errorMessages;
		}
		setFormErrors(errors);
		if (FormValidator.hasError(errors)) return;

		setIsLoadingUpdate(true);
		const body = mapAddresseeFormDataToAddresseeUpdateModel(deliveryData);
		updateAddressee(orderModel.id, body);
	};

	const updateAddressee = async (
		orderId: string,
		body: AddresseeUpdateModel
	): Promise<void> => {
		const success = await cpCommerceManagementApi.updateAddressee(
			orderId,
			body
		);
		setIsLoadingUpdate(false);
		if (!success) {
			NewToastComponent({
				status: "error",
				title: i18n.t("errorMessages.Default"),
				message: i18n.t("errorMessages.TryAgainLater"),
			});
			return;
		}
		NewToastComponent({
			status: "success",
			title: i18n.t("addresseeDialog.UpdateSuccess"),
		});
		if (callback) callback();
	};

	const handleCloseDialog = (): void => {
		setDeliveryData({
			fullName: orderModel.buyer?.fullName ?? "",
			street: orderModel.shippingData.deliveryAddress?.street ?? "",
			number: orderModel.shippingData.deliveryAddress?.number ?? "",
			complement: orderModel.shippingData.deliveryAddress?.complement ?? "",
			neighborhood: orderModel.shippingData.deliveryAddress?.neighborhood ?? "",
			city: orderModel.shippingData.deliveryAddress?.city ?? "",
			state: orderModel.shippingData.deliveryAddress?.state ?? "",
			zipCode: orderModel.shippingData.deliveryAddress?.zipCode ?? "",
		});
		setFormErrors({});
		setCurrentState(null);
	};

	return {
		deliveryData,
		isLoadingUpdate,
		isLoadingZipCode,
		setIsLoadingZipCode,
		currentState,
		setCurrentState,
		formErrors,
		handleDeliveryInfoChange,
		handleZipCodeChange,
		handleAutocompleteChange,
		handleSubmit,
		handleCloseDialog,
	};
}
