import React, { ChangeEvent } from "react";
import RetailerCard from "../components/RetailerCard";
import CustomTypography from "../../../../MUICustomComponents/CustomTypography";
import { i18n } from "../../../../translate/i18n";
import { LayoutProfile } from "../../../../domain/schemas/layoutProfile/LayoutProfile";
import { Box, Grid, SelectChangeEvent, useMediaQuery } from "@mui/material";
import CustomInput from "../../../../MUICustomComponents/CustomInput";
import ChromePicker from "../../../../components/CustomChromePicker/ChromePicker";
import { paletteObject } from "../../../../theme/foundations/palette";
import { UploadImage } from "../../../../components/UploadImage/UploadImage";
import HelpTooltip from "../../../../MUICustomComponents/HelpTooltip";

type Props = {
	identityConfig: LayoutProfile;
	onChange: (
		event: ChangeEvent<HTMLInputElement> | SelectChangeEvent<string>,
		checked?: boolean
	) => void;
	onColorAndImageChange: (
		inputName: keyof LayoutProfile,
		value: string
	) => void;
};

export default function IdentityWhiteLabel({
	identityConfig,
	onChange,
	onColorAndImageChange,
}: Props) {
	const isCustomBreakpoint = useMediaQuery(`(max-width:992px)`);

	const renderColorInput = (
		inputName: "dashboardPrimaryColor" | "dashboardSecondaryColor"
	): JSX.Element => {
		return (
			<Grid container columnSpacing={1}>
				<Grid item xs={10}>
					<CustomInput
						name={inputName}
						title={i18n.t(`retailerConfig.${inputName}`)}
						value={identityConfig[inputName]}
						onChange={onChange}
						helpTooltip={{
							title: i18n.t(`retailerConfig.DashboardColorTooltip`),
						}}
					/>
				</Grid>
				<Grid item xs={2}>
					<Box
						sx={{
							border: `2px solid ${paletteObject.grey20.main}`,
							borderRadius: 1,
							height: "100%",
							padding: 0.5,
						}}
					>
						<ChromePicker
							useOnlyParentCurentColor
							currentColor={identityConfig[inputName] ?? ""}
							updateChromePicker={(colorHex: string) => {
								onColorAndImageChange(inputName, colorHex);
							}}
						/>
					</Box>
				</Grid>
			</Grid>
		);
	};

	const renderImageInput = (
		inputName:
			| "dashboardHorizontalLightImageUrl"
			| "dashboardHorizontalDarkImageUrl"
	): JSX.Element => {
		return (
			<>
				<CustomTypography variant="small" isBold marginBottom={0.5}>
					{i18n.t(`retailerConfig.${inputName}`).toString()}
					<HelpTooltip
						helpTooltip={{
							title: i18n.t("retailerConfig.DashboardImageTooltip"),
						}}
					/>
				</CustomTypography>
				<Box height={"130px"}>
					<UploadImage
						onFileUpload={(fileUrl) =>
							onColorAndImageChange(inputName, fileUrl)
						}
						image={identityConfig[inputName] ?? ""}
						customBackgroundColor={"grey30"}
					/>
				</Box>
			</>
		);
	};

	return (
		<RetailerCard>
			<CustomTypography variant="secondaryTitle" marginBottom={2}>
				{i18n.t("retailerConfig.IdentityWhiteLabel").toString()}
			</CustomTypography>
			<Grid container spacing={2} mt={-1}>
				<Grid item xs={12} md={isCustomBreakpoint ? 12 : 6}>
					{renderColorInput("dashboardPrimaryColor")}
				</Grid>
				<Grid item xs={12} md={isCustomBreakpoint ? 12 : 6}>
					{renderColorInput("dashboardSecondaryColor")}
				</Grid>
				<Grid item xs={12} md={isCustomBreakpoint ? 12 : 6}>
					{renderImageInput("dashboardHorizontalLightImageUrl")}
				</Grid>
				<Grid item xs={12} md={isCustomBreakpoint ? 12 : 6}>
					{renderImageInput("dashboardHorizontalDarkImageUrl")}
				</Grid>
			</Grid>
		</RetailerCard>
	);
}
