import React from "react";
import ShippingTypeService from "./ShippingTypeService";
import UpdateAddresseeDialog from "../../../../components/UpdateAddresseeDialog/UpdateAddresseeDialog";
import { i18n } from "../../../../translate/i18n";

export default function ShippingType() {
	const {
		orderModel,
		addressData,
		enableAddresseDialog,
		isAddresseeDialogOpen,
		setIsAddresseeDialogOpen,
		addresseeUpdatedCallback,
	} = ShippingTypeService();

	return (
		<>
			<div className="box-title ml-0 align-items-start">
				<h2 className="card-title">
					{!orderModel.isDelivery
						? i18n.t("legacyShipping.PickupInStore").toString()
						: i18n.t("legacyShipping.Delivery").toString()}
				</h2>
				{enableAddresseDialog && (
					<a
						className="tracking-edit-link"
						style={{ paddingTop: "5px" }}
						onClick={() => setIsAddresseeDialogOpen(true)}
					>
						{i18n.t("legacyShipping.EditAddressee").toString()}
					</a>
				)}
			</div>
			<span className="card-subtitle">
				<strong>
					{!orderModel.isDelivery
						? orderModel.seller.fullName
						: orderModel.buyer?.fullName}
				</strong>
			</span>
			<span className="card-subtitle">
				{addressData.street} {addressData.number} {addressData.complement}
			</span>
			<span className="card-subtitle">
				{addressData.neighborhood} {addressData.city} {addressData.state}
			</span>
			<span className="card-subtitle">
				{addressData.zipCode &&
					`${i18n.t("legacyShipping.ZipCode")}: ${addressData.zipCode.replace(
						/(\d{5})(\d{3})/,
						"$1-$2"
					)}`}
			</span>
			{enableAddresseDialog && (
				<UpdateAddresseeDialog
					orderModel={orderModel}
					isAddresseeDialogOpen={isAddresseeDialogOpen}
					setIsAddresseeDialogOpen={setIsAddresseeDialogOpen}
					callback={addresseeUpdatedCallback}
				/>
			)}
		</>
	);
}
