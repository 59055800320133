import React from "react";
import ChromePickerService from "./ChromePickerService";
import { ChromePicker as ColorPicker } from "react-color";
import { Popover } from "@mui/material";

import * as Styled from "./ChromePickerStyles";

type ChromePickerProps = {
	updateChromePicker: (colorPicker: string) => void;
	currentColor: string;
	disabled?: boolean;
	useOnlyParentCurentColor?: boolean;
};

export default function ChromePicker({
	updateChromePicker,
	currentColor,
	disabled,
	useOnlyParentCurentColor = false,
}: ChromePickerProps) {
	const {
		// Props
		colorValue,
		anchorEl,
		openColorPicker,
		id,
		// Functions
		handleChangeColor,
		handleClick,
		handleClose,
	} = ChromePickerService({ updateChromePicker });

	return (
		<>
			<Styled.Button
				onClick={handleClick}
				disabled={disabled}
				{...(useOnlyParentCurentColor
					? { color: currentColor }
					: { color: !colorValue ? currentColor : colorValue })}
			></Styled.Button>

			<Popover
				id={id}
				open={openColorPicker}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
			>
				<ColorPicker
					color={useOnlyParentCurentColor ? currentColor : colorValue}
					onChangeComplete={handleChangeColor}
				/>
			</Popover>
		</>
	);
}
