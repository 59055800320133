import React, { createContext, useContext, useEffect, useState } from "react";
import { APIV2, API } from "../API";
import { lightOrDark } from "../infrastructure/utils/LightOrDark";
import { ThemeProvider } from "@mui/material/styles";
import { theme, createWhiteLabelTheme } from "../theme";
import { retailerManagementApi } from "../infrastructure/api/RetailerManagementApi";
import NewToastComponent from "../components/NewToastComponent";
import { layoutProfileSchema } from "../domain/schemas/layoutProfile/LayoutProfile";

export const ConfigContext = createContext({});

export default function ConfigProviders(props) {
	const [profileConfigs, setProfileConfigs] = useState({});
	const [internalConfigs, setInternalConfigs] = useState({});
	const [retailerConfigs, setRetailerConfigs] = useState({});
	const [apiAccess, setApiAccess] = useState([]);
	const [mainLoading, setMainLoading] = useState(true);
	const [currentTheme, setCurrentTheme] = useState(theme);
	let token = localStorage.getItem("token");

	useEffect(() => {
		getInternalConfigs();
		getApiAccess();
	}, []);

	const getInternalConfigs = async () => {
		try {
			if (token) {
				const response = await APIV2.get(
					"management/retailer/configurations/internal"
				);
				setInternalConfigs(response?.data);
				getProfileConfigs(response?.data?.isWhiteLabel);
				getRetailerConfigs();
			}
		} catch (error) {
			console.error(error);
		}
	};

	const getRetailerConfigs = async () => {
		try {
			const response = await APIV2.get("management/retailer/configurations");
			setRetailerConfigs(response?.data);
		} catch (error) {
			console.error(error);
		}
	};

	const getApiAccess = async () => {
		try {
			if (token) {
				const response = await API.get("api/access");
				setApiAccess(response.data);
			}
		} catch (error) {
			console.error(error);
		}
	};

	const getProfileConfigs = async (isWhiteLabel) => {
		if (!isWhiteLabel) {
			setProfileConfigs({ logo: "/images/logo-primary.svg" });
			setMainLoading(false);
			return;
		}

		let resp;
		try {
			resp = await retailerManagementApi.getLayoutProfile();
		} catch {
			setProfileConfigs({ logo: "/images/logo-primary.svg" });
			NewToastComponent({
				status: "warning",
				title: "Não foi possível aplicar identidade visual",
			});
			setMainLoading(false);
			return;
		}

		let parsed = layoutProfileSchema.safeParse(resp);
		if (
			!parsed.success ||
			!resp.dashboardPrimaryColor ||
			!resp.dashboardSecondaryColor
		) {
			setProfileConfigs({ logo: "/images/logo-primary.svg" });
			NewToastComponent({
				status: "warning",
				title: "Não foi possível aplicar identidade visual",
			});
			setMainLoading(false);
			return;
		}

		let whiteLabelTheme = createWhiteLabelTheme(
			resp.dashboardPrimaryColor,
			resp.dashboardSecondaryColor
		);
		setCurrentTheme(whiteLabelTheme);

		let root = document.documentElement;
		root.style.setProperty("--primary", resp.dashboardPrimaryColor);
		root.style.setProperty("--secondary", resp.dashboardSecondaryColor);
		setProfileConfigs({
			logo:
				lightOrDark(resp.dashboardPrimaryColor) === "light"
					? resp.dashboardHorizontalDarkImageUrl
					: resp.dashboardHorizontalLightImageUrl,
			...resp,
		});

		setMainLoading(false);
	};

	return (
		<ThemeProvider theme={currentTheme}>
			<ConfigContext.Provider
				value={{
					retailerConfigs,
					internalConfigs,
					apiAccess,
					profileConfigs,
					mainLoading,
					setMainLoading,
				}}
			>
				{props.children}
			</ConfigContext.Provider>
		</ThemeProvider>
	);
}

export const useConfig = () => useContext(ConfigContext);
