import { ChangeEvent, useState } from "react";
import { OrderModel } from "../../../domain/models/commerce/OrderModel";
import { BuyerModel } from "../../../domain/models/commerce/Buyer/BuyerModel";
import { BuyerAddressModel } from "../../../domain/models/commerce/Buyer/BuyerAddressModel";
import { ZipCodeDto } from "../../../domain/dtos/SalesApi/ZipCodeDto";
import { AutocompleteStateView } from "../../../domain/views/legacyApi/AutocompleteStateView";
import { BuyerInputs } from "./BuyerInputs";
import { SelectChangeEvent } from "@mui/material";
import { normalizeDigitsOnly } from "../../../infrastructure/utils/StringUtils";
import { cpCommerceManagementApi } from "../../../infrastructure/api/CPCommerceManagement";
import NewToastComponent from "../../../components/NewToastComponent";
import { i18n } from "../../../translate/i18n";
import { NumericDocumentType } from "../../../domain/enums/DocumentType";
import {
	FormErrors,
	FormValidations,
	FormValidator,
	InputValidator,
} from "../../../infrastructure/utils/FormUtils";

type FlattenedBuyerModel = Omit<BuyerModel, "address"> & BuyerAddressModel;

export default function BuyerEditDialogService(
	orderModel: OrderModel,
	updateCallback: Function
) {
	const [isLoadingUpdate, setIsLoadingUpdate] = useState<boolean>(false);
	const [isLoadingZipCode, setIsLoadingZipCode] = useState<boolean>(false);
	const [currentState, setCurrentState] =
		useState<AutocompleteStateView | null>(null);
	const [buyerFormData, setBuyerFormData] = useState<FlattenedBuyerModel>(
		orderModel.buyer && {
			fullName: orderModel.buyer.fullName,
			document: orderModel.buyer.document,
			documentType: orderModel.buyer.documentType,
			email: orderModel.buyer.email,
			phoneNumber: orderModel.buyer.phoneNumber,
			companyName: orderModel.buyer.companyName,
			stateInscription: orderModel.buyer.stateInscription,
			street: orderModel.buyer.address?.street ?? "",
			number: orderModel.buyer.address?.number ?? "",
			complement: orderModel.buyer.address?.complement ?? "",
			neighborhood: orderModel.buyer.address?.neighborhood ?? "",
			city: orderModel.buyer.address?.city ?? "",
			state: orderModel.buyer.address?.state ?? "",
			zipCode: orderModel.buyer.address?.zipCode ?? "",
			reference: orderModel.buyer.address?.reference ?? "",
		}
	);
	const [formErrors, setFormErrors] = useState<FormErrors>({});
	const formValidationMapper: FormValidations = {
		[BuyerInputs.FullName]: InputValidator.isRequired,
		[BuyerInputs.Document]: InputValidator.isRequired,
		[BuyerInputs.Email]: InputValidator.isRequired,
		[BuyerInputs.PhoneNumber]: InputValidator.isRequired,
		[BuyerInputs.ZipCode]: InputValidator.isZipCodeValid,
		[BuyerInputs.Street]: InputValidator.isRequired,
		[BuyerInputs.Number]: InputValidator.isRequired,
		[BuyerInputs.Neighborhood]: InputValidator.isRequired,
		[BuyerInputs.City]: InputValidator.isRequired,
		[BuyerInputs.State]: InputValidator.isRequired,
		[BuyerInputs.CompanyName]: formValidationWhenCnpj,
		[BuyerInputs.StateInscription]: formValidationWhenCnpj,
	};

	function formValidationWhenCnpj(value: string): string[] {
		if (buyerFormData.documentType !== NumericDocumentType.Cnpj) return [];
		return InputValidator.isRequired(value);
	}

	const handleSelectChange = (event: SelectChangeEvent<string>): void => {
		const { name, value } = event.target;
		setFormErrors((prevState) => ({
			...prevState,
			[BuyerInputs.CompanyName]: [],
			[BuyerInputs.StateInscription]: [],
		}));
		setBuyerFormData((prevState) => ({
			...prevState,
			document: "",
			companyName: "",
			stateInscription: "",
			[name]: value,
		}));
	};

	const handleOnChange = (event: ChangeEvent<HTMLInputElement>): void => {
		const { name, value } = event.target;
		if (formValidationMapper[name] !== undefined) {
			const errMessages: string[] = formValidationMapper[name](value);
			setFormErrors((prevState) => ({ ...prevState, [name]: errMessages }));
		}
		if (name === BuyerInputs.Document) {
			setBuyerFormData((prevState) => ({
				...prevState,
				[name]: normalizeDigitsOnly(value),
			}));
			return;
		}
		setBuyerFormData((prevState) => ({ ...prevState, [name]: value }));
	};

	const handleZipCodeChange = (
		value: string,
		zipCodeResponse?: ZipCodeDto | false
	): void => {
		const newErrors: string[] = formValidationMapper[BuyerInputs.ZipCode](
			value,
			zipCodeResponse
		);
		setFormErrors((prevState) => ({
			...prevState,
			[BuyerInputs.ZipCode]: newErrors,
			...(zipCodeResponse && {
				[BuyerInputs.City]: [],
				[BuyerInputs.Neighborhood]: [],
				[BuyerInputs.State]: [],
				[BuyerInputs.Street]: [],
			}),
		}));
		setBuyerFormData((prevState) => ({
			...prevState,
			zipCode: value,
			...(zipCodeResponse && {
				city: zipCodeResponse.city,
				complement: "",
				neighborhood: zipCodeResponse.neighborhood,
				number: "",
				state: zipCodeResponse.state,
				street: zipCodeResponse.street,
				reference: "",
			}),
		}));
	};

	const handleAutocompleteChange = (
		value: AutocompleteStateView | null
	): void => {
		setCurrentState(value);
		const stateCode = value?.code ?? "";
		const errMessages: string[] =
			formValidationMapper[BuyerInputs.State](stateCode);
		setFormErrors((prevState) => ({
			...prevState,
			[BuyerInputs.State]: errMessages,
		}));
		setBuyerFormData((prevState) => ({
			...prevState,
			[BuyerInputs.State]: stateCode,
		}));
	};

	const onCloseCallback = () => {
		const originalBuyerData: FlattenedBuyerModel = {
			companyName: orderModel.buyer.companyName,
			document: orderModel.buyer.document,
			documentType: orderModel.buyer.documentType,
			email: orderModel.buyer.email,
			fullName: orderModel.buyer.fullName,
			phoneNumber: orderModel.buyer.phoneNumber,
			stateInscription: orderModel.buyer.stateInscription,
			street: orderModel.buyer.address?.street ?? "",
			number: orderModel.buyer.address?.number ?? "",
			complement: orderModel.buyer.address?.complement ?? "",
			neighborhood: orderModel.buyer.address?.neighborhood ?? "",
			city: orderModel.buyer.address?.city ?? "",
			state: orderModel.buyer.address?.state ?? "",
			zipCode: orderModel.buyer.address?.zipCode ?? "",
			reference: orderModel.buyer.address?.reference ?? "",
		};
		setBuyerFormData(originalBuyerData);
		setFormErrors({});
		setCurrentState(null);
	};

	const handleSubmit = () => {
		let errors: FormErrors = {};
		for (const [key, value] of Object.entries(buyerFormData)) {
			if (formValidationMapper[key] === undefined) continue;
			const errorMessages: string[] = formValidationMapper[key](value);
			errors[key] = errorMessages;
		}
		setFormErrors(errors);
		if (FormValidator.hasError(errors)) return;
		setIsLoadingUpdate(true);

		const buyerBody: BuyerModel = {
			fullName: buyerFormData.fullName,
			document: buyerFormData.document,
			documentType: buyerFormData.documentType,
			email: buyerFormData.email,
			phoneNumber: buyerFormData.phoneNumber,
			companyName:
				buyerFormData.documentType === NumericDocumentType.Cnpj
					? buyerFormData.companyName
					: null,
			stateInscription:
				buyerFormData.documentType === NumericDocumentType.Cnpj
					? buyerFormData.stateInscription
					: null,
			address: {
				city: buyerFormData.city,
				neighborhood: buyerFormData.neighborhood,
				number: buyerFormData.number,
				state: buyerFormData.state,
				street: buyerFormData.street,
				zipCode: buyerFormData.zipCode,
				complement: buyerFormData.complement ?? null,
				reference: buyerFormData.reference ?? null,
			},
		};

		updateBuyer(orderModel.id, buyerBody);
	};

	const updateBuyer = async (
		orderId: string,
		body: BuyerModel
	): Promise<void> => {
		const success = await cpCommerceManagementApi.updateBuyer(orderId, body);
		setIsLoadingUpdate(false);
		if (!success) {
			NewToastComponent({
				status: "error",
				title: i18n.t("errorMessages.Default"),
				message: i18n.t("errorMessages.TryAgainLater"),
			});
			return;
		}
		NewToastComponent({
			status: "success",
			title: i18n.t("dialogBuyerEdit.UpdateSuccess"),
		});
		updateCallback();
	};

	return {
		buyerFormData,
		currentState,
		setCurrentState,
		isLoadingUpdate,
		isLoadingZipCode,
		setIsLoadingZipCode,
		formErrors,
		handleSelectChange,
		handleOnChange,
		handleZipCodeChange,
		handleAutocompleteChange,
		onCloseCallback,
		handleSubmit,
	};
}
