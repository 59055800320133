import cpOrderStatus from "./cpOrderStatusPT.json";
import cpActionLogTitle from "./cpActionLogTitlePT.json";
import legacyShipping from "./legacyShipping.json";
import legacyOrderProgress from "./legacyOrderProgressPT.json";
import dialogBuyerEdit from "./dialogBuyerEditPT.json";
import { orderDetails } from "./orderDetails";

export const cpOrdersPT = {
	cpOrderStatus,
	cpActionLogTitle,
	legacyShipping,
	legacyOrderProgress,
	dialogBuyerEdit,
	...orderDetails,
};
